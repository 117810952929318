import "../styles/layouts/news.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../assets/svg/home_elements";
import HeroNews from "../components/Hero/HeroNews/HeroNews";
import Layout from "../components/Layout/layout";
import CategoriesNews, { CategoriesLink } from "../components/News/CategoriesNews/CateroriesNews";
import GridPosts from "../components/News/GridPosts/GridPosts";
import { PostResume } from "../components/News/types";
import Pagination from "../components/Pagination/Pagination";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Photo, Slug } from "../types/shared";

interface NewsPageProps extends PageProps<Queries.PageNewsQuery> {
  pageContext: {
    humanPageNumber: number;
    limit: number;
    skip: number;
    id: string;
    nextPagePath: string;
    numberOfPages: number;
    previousPagePath: string;
  };
}

const PageNews = ({ data, pageContext }: NewsPageProps) => {
  const postList: PostResume[] = [];

  data.posts.nodes.forEach((node) => {
    postList.push({
      _id: node._id as string,
      category: node.category?.name as string,
      date: node.publishedAt as string,
      resume: node.seo.description,
      slug: node.slug as Slug,
      thumbnail: node.hero?.photo as Photo,
      title: node.hero?.title as string,
    });
  });

  return (
    <Layout>
      <div className="l-news">
        <HeroNews
          title={data.sanityPageBlog?.hero?.title}
          photo={data.sanityPageBlog?.hero?.photo as unknown as Photo}
        />
        <CategoriesNews categoriesLinkList={data.categories.nodes as CategoriesLink[]} />
        <GridPosts postsList={postList} />
        <Pagination
          nextPagePath={pageContext.nextPagePath}
          numberOfPages={pageContext.numberOfPages}
          pageNumber={pageContext.humanPageNumber}
          previousPagePath={pageContext.previousPagePath}
        />

        <WeKnowYourProblems
          title={data.sanityPageHome?.weKnowYourProblems?.title}
          problemsList={data.sanityPageHome?.weKnowYourProblems?.PAQsList as unknown as Problem[]}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default PageNews;

export const Head = ({ pageContext, data }: NewsPageProps) => {
  if (pageContext.humanPageNumber > 1) {
    return (
      <SEO
        title={`Página ${pageContext.humanPageNumber} | ${data.sanityPageBlog?.seo?.title} `}
        description={`Página ${pageContext.humanPageNumber} publicando las últimas novedades de Grupo Usieto`}
        imageAbsolutePath={data.sanityPageBlog?.seo?.thumbnail?.asset?.url}
        canonical={`noticias/${pageContext.humanPageNumber}`}
      />
    );
  }
  return (
    <SEO
      title={data.sanityPageBlog?.seo?.title}
      robots={data.sanityPageBlog?.seo?.seo_robots}
      description={data.sanityPageBlog?.seo?.description}
      canonical={data.sanityPageBlog?.seo?.canonical}
      imageAbsolutePath={data.sanityPageBlog?.seo?.thumbnail?.asset?.url}
    />
  );
};

export const query = graphql`
  query PageNews($skip: Int!, $limit: Int!) {
    sanityPageBlog {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      hero {
        title
        photo {
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
          alt
        }
      }
    }
    posts: allSanityTemplatePost(sort: { fields: publishedAt, order: DESC }, skip: $skip, limit: $limit) {
      nodes {
        _id
        _updatedAt
        publishedAt
        seo {
          description
        }

        slug {
          current
        }
        category {
          name
        }
        text: _rawContent
        hero {
          photo {
            alt
            image {
              asset {
                _id
                gatsbyImageData
              }
            }
          }
          title
        }
      }
    }
    categories: allSanityTemplateCategories {
      nodes {
        name
        slug {
          current
        }
      }
    }
    sanityPageHome {
      weKnowYourProblems {
        title
        PAQsList: _rawPaQsList
      }
    }
  }
`;
