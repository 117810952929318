import "./categories-news.scss";

import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Slug } from "../../../types/shared";
import DoNotMountComponent from "../../DoNotMountComponent/DoNotMountComponent";

export type CategoriesLink = {
  name: string;
  slug: Slug;
};

type PropsCategoriesNews = PropsWithChildren<{ categoriesLinkList: CategoriesLink[] }>;

const CategoriesNews: React.FC<PropsCategoriesNews> = (props: PropsCategoriesNews) => {
  if (!props.categoriesLinkList) return <DoNotMountComponent sms={"faltan cats"} />;
  return (
    <section
      id={"content-posts"}
      className={"o-categories-news"}>
      <div className={"c-categories-news"}>
        <Link
          className={"a-categories-news__link"}
          activeClassName={"a-categories-news__link--active"}
          to={`/noticias/`}>
          Todas
        </Link>
        {props.categoriesLinkList.map((category, index) => {
          return (
            <Link
              className={"a-categories-news__link"}
              activeClassName={"a-categories-news__link--active"}
              key={index}
              to={`/noticias/${category.slug.current}/`}>
              {category.name}
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default CategoriesNews;
