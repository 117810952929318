import "./hero-news.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Photo } from "../../../types/shared";
import DoNotMountComponent from "../../DoNotMountComponent/DoNotMountComponent";

type PropsHeroNews = PropsWithChildren<{
  title: string | undefined | null;
  photo: Photo | undefined | null;
}>;

const HeroNews: React.FC<PropsHeroNews> = (props: PropsHeroNews) => {
  if (!props.title || !props.photo) {
    return <DoNotMountComponent sms={"Fata algún dato de estos: title, resume, photo"} />;
  }
  return (
    <section className={"o-hero-news"}>
      <div className={"c-hero-news"}>
        <div className="m-hero-service__title">
          <h1 className={`a-hero-service__title`}>{props.title}</h1>
        </div>
        <SanityImage
          {...props.photo.image}
          alt={props.photo.alt}
          className={"m-hero-service__photo"}
          style={{ maxWidth: "960" }}
          width={960}
          height={895}
        />
      </div>
    </section>
  );
};

export default HeroNews;
