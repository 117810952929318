import "./grid-posts.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import PostCard from "../PostCard/PostCard";
import { PostResume } from "../types";

type PropsGridPosts = PropsWithChildren<{
  postsList: PostResume[];
}>;

const GridPosts: React.FC<PropsGridPosts> = (props: PropsGridPosts) => {
  return (
    <section className={"o-grid-posts"}>
      <div className={"c-grid-posts"}>
        {props.postsList.map((postResume) => {
          return (
            <PostCard
              key={postResume._id}
              _id={postResume._id}
              title={postResume.title}
              slug={postResume.slug}
              category={postResume.category}
              resume={postResume.resume}
              date={postResume.date}
              thumbnail={postResume.thumbnail}
            />
          );
        })}
      </div>
    </section>
  );
};

export default GridPosts;
