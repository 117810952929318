import "./post-card.scss";

import { Link, navigate } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import DoNotMountComponent from "../../DoNotMountComponent/DoNotMountComponent";
import { PostResume } from "../types";

type PropsPostCard = PropsWithChildren<PostResume>;

const PostCard: React.FC<PropsPostCard> = (props: PropsPostCard) => {
  if (!props.thumbnail) return <DoNotMountComponent sms={"Falta la imagen de la post card"} />;
  const date = new Date(props.date);
  const formatDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return (
    <article className={"m-post-card"}>
      <SanityImage
        className={"a-post-card__photo"}
        {...props.thumbnail.image}
        alt={props.thumbnail.alt}
        width={536}
        height={283}
      />
      <div className="m-post-card__info">
        <p className={"a-post-card__category"}>{props.category}</p>
        <p className={"a-title"}>
          <Link to={`/noticias/${props.slug.current}/`}>{props.title}</Link>
        </p>
        {/*<BlockContent blocks={props.resume} />*/}
        <p>{props.resume}</p>
        <div className="m-post-card__footer">
          <span>{formatDate}</span>
          <span
            // to={`noticias/${props.slug}/`}
            onClick={() => {
              navigate(`/noticias/${props.slug.current}/`);
            }}
            className={"m-button m-button--outline--light is-pointer"}>
            Leer más
          </span>
        </div>
      </div>
    </article>
  );
};

export default PostCard;
